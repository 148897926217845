/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};
// Summary And Greeting Section 

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Shivam Shukla",
  title: "Hi, I'm Shivam Shukla",
  subTitle: emoji(
    "A passionate Full Stack JavaScript Developer 🚀 having an experience of building Web and Mobile applications with HTML CSS JavaScript ReactJS NextJS and some other cool libraries and frameworks."
  ),
  allLink: "https://bit.ly/m/ishivamshukla",
  resumeLink: "https://bit.ly/4bJ8HHK",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ishivamshukla",
  linkedin: "https://www.linkedin.com/in/ishivamshukla/",
  gmail: "ishivamshukl@gmail.com",
  twitter: "https://twitter.com/itshivamshukla",
  facebook: "https://www.facebook.com/itshivamshukla",
  instagram: "https://www.instagram.com/ishivamshukla",
  whatsapp: "https://wa.me/9455733577",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false

  
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS"
    ),
    emoji(
      "⚡ Expertise in using React.js/Redux to build User Interface, strong knowledge of state store, middleware, action creator, reducer, and container."
    ),
    emoji(
      "⚡ Deep understanding of advanced Javascript concepts, such as Prototype, Closure, Scope Chain, and Promise."
    ),
    emoji(
      "⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"
    ),
    emoji(
      "⚡ Learning👨‍💻 about Blockchain, NFTs, Metaverse, Smart Contract, DApps, DAO, DeFi."
    )
    
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "HTML",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "SASS",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    // {
    //   skillName: "nextjs",
    //   fontAwesomeClassname: "fab fa-next"
    // },
    {
      skillName: "Nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    // {
    //   skillName: "tailwindss",
    //   fontAwesomeClassname: "fab fa-tailwind"
    // },
    {
      skillName: "Npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "SQL/NoSQL",
      fontAwesomeClassname: "fas fa-database"
    },
    // {
    // //   skillName: "aws",
    // //   fontAwesomeClassname: "fab fa-aws"
    // // },
    {
      skillName: "Firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "fab fa-figma",
    },
    {
      skillName: "Bootstrap",
      fontAwesomeClassname: "fab fa-bootstrap"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "fab fa-git"
    },
    {
      skillName: "Github",
      fontAwesomeClassname: "fab fa-github"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Dr. D. Y. Patil Vidyapeeth, Pune",
      logo: require("./assets/images/dpulogo.jpg"),
      subHeader: "Master of Business Administration in Project Management",
      duration: "2022 - 2024",
      desc: "Project and IT Management ",
      descBullets: [
        "DPU University, Pune is one of the UGC approved Best Private universities in India."
      ]
    },
    {
      schoolName: "Punjab Technical University",
      logo: require("./assets/images/ptulogo.png"),
      subHeader: "Bachelor of Technology in Computer Science",
      duration: "2016 - 2020",
      desc: "Operating System, Design and Analysis of Algorithm, Programming Languages, Microprocessor ...",
      descBullets: ["Punjab Technical University is one of the largest programs in the country and one of the decorated one."]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "UI", //Insert stack or technology you have experience in
      progressPercentage: "96%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Frontend", //Insert stack or technology you have experience in
      progressPercentage: "92%" //Insert relative proficiency in percentage
    },
    {
      Stack: "React", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "JavaScript",
      progressPercentage: "75%"
    },
    {
      Stack: "Backend",
      progressPercentage: "59%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Sr. Frontend Developer",
      company: "Droom Technology Private Limited",
      companylogo: require("./assets/images/droom.jpg"),
      date: "May 2023 – Present",
      desc: "India's Most Trusted Motorplace",
      descBullets: [
        "Developing the latest user-facing features using React & NextJs. Designing a modern highly responsive web-based user interface ",
        "Building reusable components and front-end libraries for future use."
      ]
    },
    {
      role: "Frontend Developer",
      company: "Orcip",
      companylogo: require("./assets/images/orcip.png"),
      date: "Mar 2021 – Dec 2022",
      desc: "Computer Software",
      descBullets: [
        "Developing the latest user-facing features using React. Designing a modern highly responsive web-based user interface ",
        "Building reusable components and front-end libraries for future use."
      ]
    },
    {
      role: "Front-End Developer",
      company: "FlyingSpark Infotech",
      companylogo: require("./assets/images/flying.jpeg"),
      date: "Aug 2020 – Jan 2021",
      desc: "Information Technology & Services",
      descBullets: [
        "Work on User Interface (UI), JavaScript, React.JS, and modifying websites."
      ]
    },
    {
      role: "Web Developer Intern",
      company: "Goldilocks Tech Solution Private Limited",
      companylogo: require("./assets/images/goldilocks.png"),
      date: "Apr 2020 – Dec 2020",
      desc: "Information Technology & Services",
      descBullets: [
        "Internship in Web Development. React.JS, Angular, Node.js, NPM, Netlify, Heroku, Working knowledge of front end optimization and performance techniques."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/hulu.jpg"),
      projectName: "Hulu-Clone",
      projectDesc: "Hulu Colne with REACT.JS(Next.js, Tailwind CSS, Responsive)",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://hulu-web-clone-beta.vercel.app/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/tesla.jpg"),
      projectName: "Tesla-Clone",
      projectDesc: "Tesla clone created with TypeScript ",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://tesla-clone-r.netlify.app/"
        }
      ]
    },
    {
      image: require("./assets/images/ineuron-logo.png"),
      projectName: "iNeuron-Website",
      projectDesc: "iNeuron clone with new feature using HTML, CSS, JavaScript ",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://ineuron-ai.netlify.app/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Complete Web Developer",
      subtitle:
        " The Complete Web Developer in 2022: Zero to Mastery",
      image: require("./assets/images/udmeyLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.udemy.com/certificate/UC-f82761f7-7e67-4d8a-99bc-8a19f4a49048/"
        }
      ]
    },
    {
      title: "React JS (Basic to Advanced)",
      subtitle:
        "React JS (Basic to Advanced) - Self Paced of duration 8 weeks.",
      image: require("./assets/images/gfgLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://media.geeksforgeeks.org/courses/certificates/17eb3aa3977ae7bbe6ce97b4deb6b086.pdf"
        }
      ]
    },

    {
      title: "JavaScript (Basic)",
      subtitle: "Completed Certifcation from Hackerrank for JavaScript(Basic)",
      image: require("./assets/images/hackerrankLogo.webp"),
      footerLink: [
        {name: "Certification", url: "https://www.hackerrank.com/certificates/a97ae2399c8b"},
        // {
        //   name: "Final Project",
        //   url: "https://olx-1.firebaseapp.com/"
        // }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://ishivamshukla.hashnode.dev/",
      title: "Hashnode Blog Profile",
      description:
        "All Blog on Hashnode Platform"
    },
    {
      url: "https://www.linkedin.com/in/ishivamshukla/",
      title: "LinkedIn Article",
      description:
        "All Article on LinkedIn Platform"
    },
    {
      url: "https://ishivamshukla.hashnode.dev/why-is-code-and-problem-solving-difficult-to-understand-and-how-to-make-it-easier",
      title: "Why is Code & Problem Solving difficult to understand – And How to Make it Easier",
      description:
        "The most important thing is to understand what you are trying to build, and how to tell if you are taking steps in the right direction."
    }

  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-9455733577",
  email_address: "ishivamshukl@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "itshivamshukla", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
